export const DISPLAY_MODES = {
  default: 'default',
  comparison: 'comparison',
  simulation: 'simulation',
};

export const PRESETS = {
  default: 'default',
  comparison: 'comparison',
  simulation: 'simulation',
  custom: 'custom',
};

export const PRESET_TYPES = {
  SUPPLIER: 'supplier',
  PRODUCTS: 'products',
};
