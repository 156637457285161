import { DateTime } from 'luxon';
import { isNil } from 'ramda';
import i18n from '@/imports/startup/client/i18n';
import { currency, float2digits, percent, percentSignDisplay } from '@/locale/numberConfig';
import { options } from '@/locale/dateConfig';
import { MISSING_DATA_TEXT } from '@/modules/purchase-management/tools/constants';

export const formatCurrency = (number, formatOptions) => {
  return typeof number === 'number' && !Number.isNaN(number)
    ? Number(number).toLocaleString(i18n.locale, formatOptions)
    : null;
};

export const formatCurrencyOrDash = (number, formatOptions) =>
  returnDashInsteadOfNull(formatCurrency(number, formatOptions));

export const formatMoney = (number) => {
  return typeof number === 'number' && !Number.isNaN(number)
    ? Number(number / 100).toLocaleString(i18n.locale, currency)
    : null;
};

export const formatMoneyOrDash = (value) => returnDashInsteadOfNull(formatMoney(value));

export const formatMoneyShekels = (number) => {
  if (number === 0) return Number(0).toLocaleString(i18n.locale, currency);

  return typeof number === 'number' && !Number.isNaN(number)
    ? Number(number).toLocaleString(i18n.locale, currency)
    : null;
};

export const formatMoneyShekelsOrDash = (value) => returnDashInsteadOfNull(formatMoneyShekels(value));

export const formatPercent = (number, maximumFractionDigits = 0) => {
  return typeof number === 'number' && !Number.isNaN(number)
    ? Number(number / 100).toLocaleString(i18n.locale, { ...percent, maximumFractionDigits })
    : null;
};

export const formatPercentWith2Fractions = (number) => {
  return typeof number === 'number' && !Number.isNaN(number)
    ? Number(number / 100).toLocaleString(i18n.locale, percentSignDisplay)
    : null;
};

export const formatPercentWith2FractionsOrDash = (value) => returnDashInsteadOfNull(formatPercentWith2Fractions(value));

export const formatPercentOrDash = (value, maximumFractionDigits = 0) =>
  returnDashInsteadOfNull(formatPercent(value, maximumFractionDigits));

export const formatBelowOnePercent = (number) => {
  if (!typeof number === 'number' && !Number.isNaN(number)) {
    return null;
  }

  if (number < 1 && number > -1) {
    return Number(number / 100).toLocaleString(i18n.locale, { ...percent });
  }
  return Number(number / 100).toLocaleString(i18n.locale, { ...percent, maximumFractionDigits: 0 });
};

export const formatFloat2Digits = (number) => {
  return typeof number === 'number' && !Number.isNaN(number)
    ? Number(number).toLocaleString(i18n.locale, float2digits)
    : null;
};

export const getCurrencySymbol = () => {
  return (0).toLocaleString(i18n.locale, currency).replace(/\d./g, '').trim();
};

export const getTrimmedCurrencySymbol = (formatOptions = currency) => (0).toLocaleString('en', formatOptions)[0];

export const formatDate = (ms) =>
  ms ? new Date(ms).toLocaleDateString(i18n.locale, { ...options.short, timeZone: 'UTC' }) : null;

export const formatDayAndTime = (ms) =>
  ms ? new Date(ms).toLocaleDateString(i18n.locale, { weekday: 'short', hour: '2-digit', minute: '2-digit' }) : null;

export const formatDayAndDate = (ms) =>
  ms ? new Date(ms).toLocaleDateString(i18n.locale, { weekday: 'short', day: '2-digit', month: 'long' }) : null;

export const formatDayDateAndTime = (ms) =>
  ms
    ? new Date(ms).toLocaleDateString(i18n.locale, {
        weekday: 'short',
        day: '2-digit',
        month: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      })
    : null;

export const formatDateAndTime = (ms) => {
  if (!ms) return null;

  return DateTime.fromMillis(ms, { options: { locale: i18n.locale } }).toFormat('dd.MM.yy, HH:mm');
};

export const formatDay = (ms) => (ms ? new Date(ms).toLocaleDateString(i18n.locale, { weekday: 'short' }) : null);

export const formatDayAndTimeWithDate = (ms) =>
  ms
    ? new Date(ms).toLocaleDateString(i18n.locale, {
        weekday: 'short',
        hour: '2-digit',
        minute: '2-digit',
        month: 'long',
        day: '2-digit',
      })
    : null;

export const formatDateWith2DigitsYear = (ms) =>
  ms ? new Date(ms).toLocaleDateString(i18n.locale, { ...options.twoDigits, timeZone: 'UTC' }) : null;

export const formatDateOrDash = (value) => returnDashInsteadOfNull(formatDate(value));

export const formatCurrentDateTimeWithWeekday = (ms, utc = true) =>
  ms
    ? new Date(ms).toLocaleDateString(i18n.locale, {
        weekday: 'short',
        day: '2-digit',
        year: '2-digit',
        month: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        ...(utc ? { timeZone: 'UTC' } : {}),
      })
    : null;

export const formatDuration = (ms) => (ms ? DateTime.fromMillis(ms).toRelative({ locale: i18n.locale }) : null);

export const formatNumber = (number) => {
  return number && typeof number === 'number' && !Number.isNaN(number)
    ? Number(number).toLocaleString(i18n.locale, float2digits)
    : null;
};

export const formatNumberOrDash = (value) => returnDashInsteadOfNull(formatNumber(value));

export const formatNumberOrDefaultZero = (number) => {
  return number && typeof number === 'number' && !Number.isNaN(number)
    ? Number(number).toLocaleString(i18n.locale, float2digits)
    : '0';
};

export const returnDashInsteadOfNull = (result) => {
  return isNil(result) ? MISSING_DATA_TEXT : result;
};

export const getDateRangeStringFromDates = (startDate, endDate) => {
  const format = 'LLL yy';
  const formattedStartDate = startDate.toFormat(format);
  const formattedEndDate = endDate.toFormat(format);

  if (formattedStartDate === formattedEndDate) {
    return formattedStartDate;
  }

  return `${formattedStartDate} - ${formattedEndDate}`;
};

export const getPeriodText = (index) => {
  switch (index) {
    case 0:
      return i18n.t('terms.supplierTerms.supplierTermTable.first');
    case 1:
      return i18n.t('terms.supplierTerms.supplierTermTable.second');
    case 2:
      return i18n.t('terms.supplierTerms.supplierTermTable.third');
    default:
      return '';
  }
};

export const getISODateFromDate = (date) => {
  return date.toISOString().split('T')[0];
};

export const getISODateFromDateTime = (date) => {
  return date.toISODate();
};
