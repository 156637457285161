// DEPRECATED FILE!!!!

import { gql } from '@apollo/client/core';
import camelCase from 'camelcase';
import isNil from 'ramda/src/isNil';
import filter from 'ramda/src/filter';
import pipe from 'ramda/src/pipe';
import not from 'ramda/src/not';
import map from 'ramda/src/map';
import pick from 'ramda/src/pick';

export const TERM_CREATE_MUTATION = gql`
  mutation termCreate($termCreateInput: TermCreateInput) {
    termCreate(termCreateInput: $termCreateInput) {
      id
    }
  }
`;

export const TERM_CREATE_MUTATION_NEW = gql`
  mutation termCreate($termCreateInput: TermCreateInputNew) {
    termCreateNew(termCreateInput: $termCreateInput) {
      id
    }
  }
`;

export const SUPPLIER_TERMS_OVERVIEW_QUERY = gql`
  query supplierTermsOverview($supplierId: ID!) {
    supplierTermsOverview(supplierId: $supplierId) {
      product {
        id
        name
        sku
      }
      currentTerms {
        id
        type
        fromDate
        toDate
        pricingMethod
        price
        priceIndexId
        rewardType
        rewardValue
        rewardRealizationTime
        conditionType
        conditionValue
        conditionLimit
        updatedAt
        updatedBy
      }
      previousMonthAmount
      previousMonthQuantity
      monthlyAverageAmount
      monthlyAverageQuantity
      last12MonthsAmount
      last12MonthsQuantity
    }
  }
`;

export const TERMS_OVERVIEW_QUERY = gql`
  query termsOverview {
    termsOverview {
      supplier {
        id
        name
      }
      productCount
      untrackedCount
      currentMonthAmount
      previousMonthAmount
      last12MonthsAmount
      agreements {
        id
      }
    }
  }
`;

const filterNil = filter(pipe(isNil, not));
const camelCaseFields = [
  'type',
  'pricingMethod',
  'rewardType',
  'rewardRealizationTime',
  'conditionType',
  'conditionLimit',
];
const transformFieldsToCamelCase = pipe(pick(camelCaseFields), map(camelCase));
const dateFields = ['fromDate', 'toDate', 'updatedAt'];
const transformDateFields = pipe(
  pick(dateFields),
  map((ms) => new Date(ms))
);

export const transformTerm = pipe(filterNil, (term) => ({
  ...term,
  ...transformFieldsToCamelCase(term),
  ...transformDateFields(term),
}));
