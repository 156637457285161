import { DateTime } from 'luxon';

export const validateMainDateEntryRouteParam = (root) => {
  const { query } = root.$route;

  const defaultStartDate = DateTime.local().minus({ month: 3 }).startOf('month');
  const defaultEndDate = DateTime.local();

  if (!query[`fromDate0`] && !query[`toDate0`]) {
    return [defaultStartDate, defaultEndDate];
  }

  if (query[`fromDate0`] && query[`toDate0`]) {
    const startDateFromQueryParam = DateTime.fromISO(query[`fromDate0`]);
    const endDateFromQueryParam = DateTime.fromISO(query[`toDate0`]);

    if (startDateFromQueryParam.isValid && endDateFromQueryParam.isValid) {
      return [startDateFromQueryParam, endDateFromQueryParam];
    }
    root.$router.replace({ query: {} });
    return [defaultStartDate, defaultEndDate];
  }

  root.$router.replace({ query: {} });
  return [defaultStartDate, defaultEndDate];
};

export const validateRestDateEntriesRouteParams = (root) => {
  const { query } = root.$route;

  const isQueryKeyDateValid = (key) => DateTime.fromISO(query[key]).isValid;

  for (const key of Object.keys(query)) {
    if (key.startsWith('fromDate') && key !== 'fromDate0') {
      const dateQueryIndex = key.split('fromDate')[1];

      const isDateComparisonQueryValid =
        query[`toDate${dateQueryIndex}`] && isQueryKeyDateValid(key) && isQueryKeyDateValid(`toDate${dateQueryIndex}`);

      if (!isDateComparisonQueryValid) {
        return root.$router.replace({ query: {} });
      }
    }
  }
};
