import { i18n } from '@/imports/startup/client/i18n';

export const EXPORT_ACTIONS = {
  EXPORT_TABLE: 'table',
  EXPORT_ALL_PRODUCTS: 'allProducts',
};

export const exportExcelOptions = () => [
  {
    action: EXPORT_ACTIONS.EXPORT_TABLE,
    label: i18n.t('terms.supplierTerms.supplierTermTable.excel.exportTableToExcel'),
  },
  {
    action: EXPORT_ACTIONS.EXPORT_ALL_PRODUCTS,
    label: i18n.t('terms.supplierTerms.supplierTermTable.excel.exportAllProductsToExcel'),
  },
];
