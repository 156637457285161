import { DateTime } from 'luxon';
import i18n from '@/imports/startup/client/i18n';

const monthUnit = 'month';
const yearUnit = 'year';

const currentMonthStart = DateTime.local().startOf(monthUnit);
const currentMonthEnd = currentMonthStart.endOf(monthUnit);

const lastMonthStart = currentMonthStart.minus({ month: 1 });
const startOfLastYear = currentMonthStart.minus({ year: 1 }).startOf(yearUnit);

export const datePickerShortcutsConfiguration = () => [
  {
    startDate: currentMonthStart,
    endDate: currentMonthEnd,
    text: i18n.t(`terms.supplierTerms.supplierTermTable.settings.dateShortcuts.currentMonth`, {
      month: currentMonthStart.toFormat('LLL'),
    }),
  },
  {
    startDate: lastMonthStart,
    endDate: lastMonthStart.endOf(monthUnit),
    text: i18n.t(`terms.supplierTerms.supplierTermTable.settings.dateShortcuts.lastMonth`, {
      month: lastMonthStart.toFormat('LLL'),
    }),
  },
  {
    startDate: currentMonthStart.startOf(yearUnit),
    endDate: currentMonthStart.endOf(yearUnit),
    text: i18n.t(`terms.supplierTerms.supplierTermTable.settings.dateShortcuts.currentYear`, {
      year: currentMonthStart.toFormat('yyyy'),
    }),
  },
  {
    startDate: startOfLastYear,
    endDate: startOfLastYear.endOf(yearUnit),
    text: i18n.t(`terms.supplierTerms.supplierTermTable.settings.dateShortcuts.lastYear`, {
      year: startOfLastYear.toFormat('yyyy'),
    }),
  },
  {
    startDate: currentMonthStart.minus({ month: 13 }).startOf(monthUnit),
    endDate: currentMonthEnd.minus({ month: 1 }).endOf(monthUnit),
    text: i18n.t(`terms.supplierTerms.supplierTermTable.settings.dateShortcuts.last12Months`),
  },
  {
    startDate: currentMonthEnd.minus({ month: 3 }).startOf(monthUnit),
    endDate: currentMonthEnd,
    text: i18n.t(`terms.supplierTerms.supplierTermTable.settings.dateShortcuts.currentMonthAnd3PLastMonths`),
  },
];

export const generate3MonthsBackwardDateRange = ({ query, index, startDate }) => {
  const queryParamFromDate = query[`fromDate${index}`];
  const queryParamToDate = query[`toDate${index}`];

  return {
    startDate: queryParamFromDate
      ? DateTime.fromISO(queryParamFromDate)
      : startDate.minus({ months: 3 }).startOf('month'),
    endDate: queryParamToDate ? DateTime.fromISO(queryParamToDate) : startDate.minus({ months: 1 }).endOf('month'),
  };
};

export const generate1YearBackwardsDateRange = ({ query, index, startDate, endDate }) => {
  const queryParamFromDate = query[`fromDate${index}`];
  const queryParamToDate = query[`toDate${index}`];

  return {
    startDate: queryParamFromDate
      ? DateTime.fromISO(queryParamFromDate)
      : startDate.minus({ years: 1 }).startOf('month'),
    endDate: queryParamToDate ? DateTime.fromISO(queryParamToDate) : endDate.minus({ years: 1 }).endOf('month'),
  };
};
