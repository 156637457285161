export const LESS_THAN_LTR = '<';
export const MISSING_DATA_TEXT = '-';
export const BANK_NUMBER_TO_BANK_NAME = {
  4: 'בנק יהב',
  9: 'בנק הדואר',
  10: 'בנק לאומי',
  11: 'בנק דיסקונט',
  12: 'בנק הפועלים',
  13: 'בנק איגוד',
  14: 'אוצר החייל (מקבוצת הבנק הבינלאומי)',
  17: 'בנק מרכנתיל דיסקונט',
  18: 'הבנק הדיגיטלי הראשון',
  20: 'בנק מזרחי',
  22: 'סיטי בנק (Citibank)',
  23: 'HSBC',
  26: 'Ubank (מקבוצת הבנק הבינלאומי)',
  31: 'בנק הבינלאומי',
  46: 'בנק מסד',
  52: 'בנק פאגי - פועלי אגודת ישראל (מקבוצת הבנק הבינלאומי)',
  54: 'בנק ירושלים',
};

export const BUSINESS_TYPES = {
  serviceProvider: 'SERVICE_PROVIDER',
  restaurant: 'RESTAURANT',
  supplier: 'SUPPLIER',
};
