import i18n from '@/imports/startup/client/i18n';
import { exportToExcel } from '@/imports/ui/file_exports';
import { getTrimmedCurrencySymbol } from './purchaseManagementFormatters';
import { exportSupplierProducts } from './components/supplierProductsPurchaseManagement/supplierProductsTableExcel';
import { getSupplierProductsColumns } from './components/supplierProductsPurchaseManagement/supplierProductsTableColumns';
import { COLUMN_CATEGORIES } from './components/supplierProductsPurchaseManagement/supplierProductsTableConstants';

const getExcelFmt = (columnType, currencyFormat) => {
  const currencySymbol = getTrimmedCurrencySymbol(currencyFormat);

  switch (columnType) {
    case EXCEL_TYPES.CURRENCY:
      return `"${currencySymbol}"#,##0.00`;
    case EXCEL_TYPES.ROUNDED_CURRENCY:
      return `"${currencySymbol}"#0`;
    case EXCEL_TYPES.DATE:
      return 'dd/mm/yyyy';
    case EXCEL_TYPES.PERCENTAGE:
      return '#,##0.00%';
    case EXCEL_TYPES.ROUNDED_PERCENTAGE:
      return '#0%';
    default:
      return null;
  }
};

export const EXCEL_TYPES = {
  CURRENCY: 'currency',
  ROUNDED_CURRENCY: 'roundedCurrency',
  ROUNDED_PERCENTAGE: 'roundedPercentage',
  DATE: 'date',
  PERCENTAGE: 'percentage',
};

export const buildColumnsByTableViewForExcel = ({
  tableColumns,
  amountOfComparison = undefined,
  dateRanges = undefined,
  columnsNotInComparison = [],
  currencyFormat,
}) => {
  const columns = [];
  if (!amountOfComparison) {
    columnsNotInComparison = tableColumns.map((column) => column.key);
  }

  tableColumns.forEach((column) => {
    const notInComparison = columnsNotInComparison.includes(column.key);
    if (notInComparison) {
      const newColumn = {
        key: column.key,
        label: column.header,
      };

      const numFmt = getExcelFmt(column.type, currencyFormat);

      if (numFmt) {
        newColumn['numFmt'] = numFmt;
      }

      columns.push(newColumn);
    } else {
      for (let i = 0; i < amountOfComparison; i++) {
        const newColumn = {
          key: `${column.key}-${i}`,
          label: `${column.header}-${dateRanges[i]}`,
        };

        const numFmt = getExcelFmt(column.type, currencyFormat);

        if (numFmt) {
          newColumn['numFmt'] = numFmt;
        }

        columns.push(newColumn);
      }
    }
  });

  return columns;
};

export const exportAllProducts = ({ productsSummary, suppliersSummary, dateRange, currencyFormat }) => {
  if (!productsSummary || !suppliersSummary) {
    throw new Error('Relevant data is missing');
  }
  const defaultSupplierProductsColumns = getSupplierProductsColumns();
  const relevantColumns = defaultSupplierProductsColumns
    .filter(({ category }) => category === COLUMN_CATEGORIES.PRODUCTS_PURCHASED)
    .map((column) => ({ ...column, hidden: false }));
  const { columns, data } = exportSupplierProducts({
    productItems: productsSummary,
    returnOnlyDataAndColumns: true,
    tableColumns: relevantColumns,
    currencyFormat,
  });
  const supplierNameColumn = {
    key: 'supplierName',
    label: i18n.t('terms.termsTable.header.supplier'),
  };
  columns.splice(2, 0, supplierNameColumn);
  const dataWithSuppliers = data
    .map((orderItem) => {
      const supplierRow = suppliersSummary.find(({ supplier }) => supplier.id === orderItem.supplierId);
      return {
        ...orderItem,
        minPrice: orderItem.minPriceOrderItem?.netAmountPerUnit ?? '-',
        maxPrice: orderItem.maxPriceOrderItem?.netAmountPerUnit ?? '-',
        supplierName: supplierRow.supplier.name,
      };
    })
    .sort((a, b) => a.supplierName.toUpperCase().localeCompare(b.supplierName.toUpperCase()));

  const metadata = {
    filename: i18n.t('terms.supplierTerms.supplierTermTable.exportAllProductsFilename', { period: dateRange }),
    sheetname: i18n.t('terms.supplierTerms.supplierTermTable.exportAllProductsSheetname'),
    direction: i18n.t('direction'),
    columns,
  };

  exportToExcel(metadata, dataWithSuppliers);
};
